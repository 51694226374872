export default class Expertises {
    constructor(Swiper, SwiperModules) {
        this.element = document.querySelector('#expertises');
        this.Swiper = Swiper;
        this.modules = SwiperModules;
        this.slider = false;
        this.medias = {
            large: window.matchMedia('(min-width: 64rem)'),
            hover: window.matchMedia('(hover: hover)'),
        }

        if(this.element) {
            this.update();
            this.medias.large.addEventListener('change', (event) => this.update(event.matches, Swiper));
            this.medias.hover.addEventListener('change', (event) => this.update(event.matches, Swiper));
        }
    }

    update() {
        if(!this.medias.large.matches || !this.medias.hover.matches) {
            this.setupControls();
            this.slider = this.setupSwiper();
        } else if(!!this.slider) {
            this.element.querySelector('.swiper-controls').remove();
            this.slider.destroy();
        }
    }

    get swiperCfg() {
        const dot = '<svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" fill="none"><path fill="var(--attenuated, black)" d="M4 .172c3.6-.874 4 1.734 4 3.871 0 2.138-.84 3.315-4 3.872-3.16.558-4-1.737-4-3.872 0-2.134.4-3 4-3.871Z"/></svg>';
        return {
            modules: this.modules,
            loop: true,
            initialSlide: 1,
            slidesPerView: 1,
            slidesPerGroup: 1,
            spaceBetween: -50,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                renderBullet: (index, className) => {
                    return `<span class="${className}">${dot}</span>`;
                },
            },
        }
    }

    setupControls() {
        if(this.element.querySelector('.swiper-controls'))
            return;

        const wrapper = this.element.querySelector('.swiper-wrapper');
        const arrowImg = `<img src="${themeUrl}/img/icons/arrow-horizontal.svg" alt="">`;
        wrapper.parentElement.parentElement.innerHTML += `
            <div class="swiper-controls">
                <div class="swiper-pagination"></div>
                <div class="swiper-navigation">
                    <button class="swiper-button-prev op-u-nudebtn">${arrowImg}</button>
                    <button class="swiper-button-next op-u-nudebtn">${arrowImg}</button>
                </div>
            </div>`;
    }

    setupSwiper() {
        return new this.Swiper(
            this.element.querySelector('.swiper-container'),
            this.swiperCfg
        );
    }
}
